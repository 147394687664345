function Footer() {
   return (
      <>
         <footer className="bg-footer text-center text-lg-start text-white mt-5">
            <div className="text-center p-3">
               © 2024 by <strong>Polite Cat Token</strong>. All rights reserved
            </div>
         </footer>
      </>
   );
}

export default Footer;
