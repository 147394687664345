import { useState } from "react";

function Navbar() {
   const [isNavCollapsed, setIsNavCollapsed] = useState(true);

   const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

   return (
      <>
         <nav className="navbar navbar-expand-lg border-header">
            <div className="container">
               <a className="navbar-brand" href="/">
                  Polite Cat
               </a>

               <button
                  class="custom-toggler navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarsExample09"
                  aria-controls="navbarsExample09"
                  aria-expanded={!isNavCollapsed ? true : false}
                  aria-label="Toggle navigation"
                  onClick={handleNavCollapse}
               >
                  <span class="navbar-toggler-icon"></span>
               </button>

               <div
                  class={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
                  id="navbarsExample09"
               >
                  <ul class="navbar-nav ms-auto text-center">
                     <li class="nav-item active">
                        <a class="nav-link" href="/#about">
                           About
                        </a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/#tokenomics">
                           Tokenomics
                        </a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/#roadmap">
                           Roadmap
                        </a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/#">
                           Presale
                        </a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/#">
                           Memeverse
                        </a>
                     </li>
                     <li className="nav-item">
                        <a
                           style={{ borderRadius: "0.5rem" }}
                           href="https://forms.gle/zSBeTHeTcj9sKUck8"
                           target="_blank"
                           rel="noreferrer"
                           className="btn btn-sm btn-outline-danger airdrop-btn"
                        >
                           Airdrop
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
         </nav>
      </>
   );
}

export default Navbar;
