function RoadmapSection() {
   return (
      <>
         <div
            id="roadmap"
            className="row"
            style={{ paddingTop: "7rem", paddingBottom: "8rem" }}
         >
            <div className="col-md-3">
               <img
                  src="https://i.ibb.co/f95gQ9B/polite-copy.png"
                  alt="Polite Cat"
               />
            </div>
            <div className="col-md-6 py-3">
               <div className="card">
                  <div className="card-body card-border">
                     <h2 className="sh fw-bold mb-3">Roadmap</h2>
                     <ul>
                        <li>
                           <s>Polite Cat Token Born</s>
                        </li>
                        <li>Promotion & build community</li>
                        <li>Start airdrop, presale, etc</li>
                        <li>Add liquidity, burn, airdrop distribution</li>
                        <li>Create more event & more fun!</li>
                        <li>$POL Takeover the world!</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div className="col-md-3">
               <img
                  src="https://i.ibb.co/r3J81hh/polite.png"
                  alt="Polite Cat"
               />
            </div>
         </div>
      </>
   );
}

export default RoadmapSection;
