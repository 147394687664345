function HeroSection() {
   return (
      <>
         <div
            className="row py-5 align-items-center"
            style={{ marginTop: "50px" }}
         >
            <div className="col-md-6">
               <h1 className="fw-bold">$POL</h1>
               <p className="mb-4 hero-text">
                  The most polite memecoin ever. Token living on Base chain
                  network.
               </p>
               <ul className="socmed">
                  <li>
                     <a
                        href="https://twitter.com/PoliteCatToken"
                        target="_blank"
                        rel="noreferrer"
                     >
                        <img
                           src="https://static.wixstatic.com/media/1f3f2b_8eaff3e5f42b45f88cf11d418e25d5e6~mv2.png/v1/fill/w_100,h_100,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/twitter%20(2).png"
                           alt="Twitter"
                           width={48}
                        />
                     </a>
                  </li>
                  <li>
                     <a
                        href="https://t.me/gopolitecat"
                        target="_blank"
                        rel="noreferrer"
                     >
                        <img
                           src="https://static.wixstatic.com/media/1f3f2b_0078029a112449e5b8d712401bfbd5ac~mv2.png/v1/fill/w_100,h_100,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/telgrrgrgrgr.png"
                           alt="Twitter"
                           width={48}
                        />
                     </a>
                  </li>
                  <li>
                     <a
                        href="https://basescan.org/token/0x8824ed75154ea6e2c089e221e891898db7dcb174"
                        target="_blank"
                        rel="noreferrer"
                     >
                        <img
                           src="https://static.wixstatic.com/media/1f3f2b_95d714edb8484668b50ec07fb8b4f394~mv2.png/v1/fill/w_100,h_100,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/etherscan.png"
                           alt="Twitter"
                           width={48}
                        />
                     </a>
                  </li>
               </ul>
            </div>
            <div className="col-md-6">
               <img
                  src="https://i.ibb.co/r3J81hh/polite.png"
                  alt="Polite Cat"
               />
            </div>
         </div>
      </>
   );
}

export default HeroSection;
