import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/js/src/collapse.js";
import { clarity } from "react-microsoft-clarity";

import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import AboutSection from "./components/AboutSection";
import Footer from "./components/Footer";
import TokenomicSection from "./components/TokenomicSection";
import RoadmapSection from "./components/RoadmapSection";

function App() {
   clarity.init("ltcg03lnx0");
   return (
      <div className="App">
         <Navbar />

         <div className="container">
            <HeroSection />

            <AboutSection />

            <TokenomicSection />

            <RoadmapSection />
         </div>

         <Footer />
      </div>
   );
}

export default App;
