function TokenomicSection() {
   return (
      <>
         <div className="py-3"></div>
         <div className="row py-5 align-items-center" id="tokenomics">
            <div className="col-md-6">
               <h2 className="sh fw-bold mb-4" style={{ fontSize: "2.5rem" }}>
                  Tokenomics
               </h2>
               <div className="card" style={{ fontSize: "1.2rem" }}>
                  <div className="card-body card-body-2">
                     Total Supply: <b>10,000,000,000 $POL</b>
                  </div>
               </div>
               <div className="card mt-3" style={{ fontSize: "1.2rem" }}>
                  <div className="card-body card-body-2">
                     <i
                        className="bi bi-check-circle text-success"
                        style={{ marginRight: "5px" }}
                     ></i>{" "}
                     No Taxes, fee, etc
                  </div>
               </div>
               <div className="card mt-3" style={{ fontSize: "1.2rem" }}>
                  <div className="card-body card-body-2">
                     <i
                        className="bi bi-check-circle text-success"
                        style={{ marginRight: "5px" }}
                     ></i>{" "}
                     100% Presale goes to LP
                  </div>
               </div>
               <div className="card mt-3" style={{ fontSize: "1.2rem" }}>
                  <div className="card-body card-body-2">
                     <i
                        className="bi bi-check-circle text-success"
                        style={{ marginRight: "5px" }}
                     ></i>{" "}
                     365 days LP locked!
                  </div>
               </div>
               <div className="card mt-3" style={{ fontSize: "1.2rem" }}>
                  <div className="card-body card-body-2">
                     <i
                        className="bi bi-check-circle text-success"
                        style={{ marginRight: "5px" }}
                     ></i>{" "}
                     Token ownership is renounced
                  </div>
               </div>
            </div>
            <div className="col-md-6">
               <img
                  src="https://i.ibb.co/7gMpPkc/Screenshot-from-2024-04-07-03-50-45-removebg-preview.png"
                  alt="Tokenomics"
                  className="float-end mt-3"
               />
            </div>
         </div>
      </>
   );
}

export default TokenomicSection;
