function AboutSection() {
   return (
      <>
         <div className="row py-5" id="about">
            <div className="col-md-6 mb-5">
               <iframe
                  style={{ minHeight: "320px" }}
                  title="Polite Cat"
                  src="https://www.youtube.com/embed/EpFJAKC3pUo"
                  frameBorder={0}
                  allowFullScreen={true}
               ></iframe>
               <b>Video Courtesy: ma2cysia</b>
            </div>
            <div className="col-md-6">
               <div className="card">
                  <div className="card-body card-border">
                     <h2 className="sec-header sh mb-3">About</h2>
                     <p>
                        Fueled by the timeless power of memes, $POL brings a
                        dash of sophistication to the table, courtesy of our
                        beloved Polite Cat. Navigating the crypto universe has
                        never been so refined, guided by a dapper paw and the
                        most gracious of purrs.
                     </p>
                     <p>
                        Join the $POL party today and dive headfirst into the
                        memeverse madness. Let's make memecoins fun again, one
                        polite meow at a time, all within the colorful confines
                        of the Ethereum base chain.
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default AboutSection;
